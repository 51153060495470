import constants from './constants';

const initialState = {
  name: '',
  email: '',
  theme: '',
  message: '',
  accept: true
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.name:
      return {
        ...state,
        name: action.payload.name
      };
    case constants.email:
      return {
        ...state,
        email: action.payload.email
      };
    case constants.theme:
      return {
        ...state,
        theme: action.payload.theme
      };
    case constants.message:
      return {
        ...state,
        message: action.payload.message
      };
    case constants.accept:
      return {
        ...state,
        accept: action.payload.accept
      };
    default:
      return state;
  }
}
