import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import configureStore from './store/configureStore';
import RouterList from './router';

const store = configureStore();

const Application = (
  <Provider store={store}>
    <BrowserRouter>
      <RouterList />
    </BrowserRouter>
  </Provider>
);

window.onload = () => {
  Loadable.preloadReady().then(() => {
    ReactDOM.hydrate(Application, document.getElementById('root'));
  });
};
