import React from 'react';
import './style.less';

const Container = props => (
  <div {...props} className={props.className ? props.className + ' container' : 'container'}>
    {props.children}
  </div>
);

export default Container;
