import React, { Component } from 'react';
import './style.less';

export default class Modal extends Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
    document.onkeydown = e => {
      if (e.key === 'Escape') {
        this.props.close();
      }
    };
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
    document.onkeydown = null;
  }

  layerClick(e) {
    if (e.target === this.modalScroll) {
      this.props.close();
    }
  }

  render() {
    const props = this.props;

    return (
      <div className="modal-container">
        <div
          ref={modalScroll => (this.modalScroll = modalScroll)}
          className="modal-scroll"
          onClick={e => this.layerClick(e)}
        >
          <div className={props.className ? 'modal ' + props.className : 'modal'}>
            <a className="modal-close" href="#123" onClick={props.close}>
              &times;
            </a>

            <div className="modal-content">{props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}
