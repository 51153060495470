import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import navigation from '../nav-conf';
import { Burger } from '../../../guideline';
import { links } from '../../../../routes';
import MobileNav from './nav-menu';
import './style.less';

export default class MobileMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      menuType: null
    };
  }

  toggle() {
    const isOpen = this.state.isOpen;
    isOpen && this.burgerToggle.classList.add('hide');
    document.body.style.overflow = isOpen ? 'auto' : 'hidden';

    setTimeout(
      () => {
        this.setState({ isOpen: !isOpen, menuType: null });
      },
      isOpen ? 500 : 0
    );
  }

  render() {
    const { isOpen, menuType } = this.state;
    const { location } = this.props;

    return (
      <div className="mobile-menu">
        <a className="phone" href={links.phone}>
          {links.phoneText}
        </a>

        <Burger isOpen={isOpen} onClick={() => this.toggle()} />

        {isOpen && (
          <div className="burger-toggle" ref={toggle => (this.burgerToggle = toggle)}>
            {navigation(location).map((link, key) => (
              <Link
                key={key}
                className={link.className}
                to={link.type ? location.pathname : link.to}
                onClick={() => (link.type ? this.setState({ menuType: link.type }) : this.toggle())}
              >
                {link.text}
              </Link>
            ))}

            {menuType && <MobileNav type={menuType} location={location} />}
          </div>
        )}
      </div>
    );
  }
}
