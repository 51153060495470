export default {
  tab: 'FORM_TAB',
  paymentPeriod: 'FORM_PAYMENT_PERIOD',
  paymentType: 'FORM_PAYMENT_TYPE',
  sumList: 'FORM_SUM_LIST',
  anotherSum: 'FORM_ANOTHER_SUM',
  projects: 'FORM_PROJECTS',
  isAnonymous: 'FORM_IS_ANONYMOUS',
  email: 'FORM_EMAIL',
  firstName: 'FORM_FIRST_NAME',
  lastName: 'FORM_LAST_NAME',
  validate: 'FORM_VALIDATE',
  accept: 'FORM_ACCEPT',
  cancelModal: 'FORM_CANCEL_MODAL',
  cancelEmail: 'FORM_CANCEL_EMAIL'
};
