import constants from './constants';

const initialState = {
  newsDetail: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.newsDetail:
      return {
        ...state,
        newsDetail: action.payload.newsDetail
      };
    default:
      return state;
  }
}
