import React from 'react';
import Loadable from 'react-loadable';
import { Switch, Route } from 'react-router-dom';
import Layout from './components/layout';
import { Loader } from './components/guideline';
import { pages } from './routes';

const Main = Loadable({
  loader: () => import(/* webpackChunkName: 'main-page' */ './components/main'),
  loading: () => <Loader size={40} />,
  modules: ['main-page']
});

const Contacts = Loadable({
  loader: () => import(/* webpackChunkName: 'contacts-page' */ './components/contacts'),
  loading: () => <Loader size={40} />,
  modules: ['contacts-page']
});

const Policy = Loadable({
  loader: () => import(/* webpackChunkName: 'policy-page' */ './components/policy'),
  loading: () => <Loader size={40} />,
  modules: ['policy-page']
});

const Offer = Loadable({
  loader: () => import(/* webpackChunkName: 'policy-page' */ './components/offer'),
  loading: () => <Loader size={40} />,
  modules: ['policy-page']
});

const PaymentDone = Loadable({
  loader: () => import(/* webpackChunkName: 'payment-page' */ './components/payment-done'),
  loading: () => <Loader size={40} />,
  modules: ['payment-page']
});

const Projects = Loadable({
  loader: () => import(/* webpackChunkName: 'projects-page' */ './components/projects'),
  loading: () => <Loader size={40} />,
  modules: ['projects-page']
});

const Project = Loadable({
  loader: () => import(/* webpackChunkName: 'project-page' */ './components/project'),
  loading: () => <Loader size={40} />,
  modules: ['project-page']
});

const Employees = Loadable({
  loader: () => import(/* webpackChunkName: 'employees-page' */ './components/employees'),
  loading: () => <Loader size={40} />,
  modules: ['employees-page']
});

const Employee = Loadable({
  loader: () => import(/* webpackChunkName: 'employee-page' */ './components/employee'),
  loading: () => <Loader size={40} />,
  modules: ['employee-page']
});

const News = Loadable({
  loader: () => import(/* webpackChunkName: 'news-page' */ './components/news'),
  loading: () => <Loader size={40} />,
  modules: ['news-page']
});

const NewsDetail = Loadable({
  loader: () => import(/* webpackChunkName: 'news-detail-page' */ './components/news-detail'),
  loading: () => <Loader size={40} />,
  modules: ['news-detail-page']
});

const ProjectNews = Loadable({
  loader: () => import(/* webpackChunkName: 'project-news-page' */ './components/project-news'),
  loading: () => <Loader size={40} />,
  modules: ['project-news-page']
});

const Partners = Loadable({
  loader: () => import(/* webpackChunkName: 'partners-page' */ './components/partners'),
  loading: () => <Loader size={40} />,
  modules: ['partners-page']
});

const Mission = Loadable({
  loader: () => import(/* webpackChunkName: 'mission-page' */ './components/mission-and-targets'),
  loading: () => <Loader size={40} />,
  modules: ['mission-page']
});

const BoardOfTrustees = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'board-trustees-page' */ './components/board-of-trustees'),
  loading: () => <Loader size={40} />,
  modules: ['board-trustees-page']
});

const FoundingDocuments = Loadable({
  loader: () => import(/* webpackChunkName: 'documents-page' */ './components/founding-documents'),
  loading: () => <Loader size={40} />,
  modules: ['documents-page']
});

const Reports = Loadable({
  loader: () => import(/* webpackChunkName: 'reports-page' */ './components/reports'),
  loading: () => <Loader size={40} />,
  modules: ['reports-page']
});

const Requisites = Loadable({
  loader: () => import(/* webpackChunkName: 'requisites-page' */ './components/requisites'),
  loading: () => <Loader size={40} />,
  modules: ['requisites-page']
});

const ThanksgivingLetters = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'thanksgiving-page' */ './components/thanksgiving-letters'),
  loading: () => <Loader size={40} />,
  modules: ['thanksgiving-page']
});

const MassMedia = Loadable({
  loader: () => import(/* webpackChunkName: 'mass-media-page' */ './components/mass-media'),
  loading: () => <Loader size={40} />,
  modules: ['mass-media-page']
});

const CorporateDonations = Loadable({
  loader: () => import(/* webpackChunkName: 'corporate-page' */ './components/corporate-donations'),
  loading: () => <Loader size={40} />,
  modules: ['corporate-page']
});

const Volunteer = Loadable({
  loader: () => import(/* webpackChunkName: 'volunteer-page' */ './components/volunteer'),
  loading: () => <Loader size={40} />,
  modules: ['volunteer-page']
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: 'admin-page' */ './components/admin'),
  loading: () => <Loader size={40} />,
  modules: ['admin-page']
});

const AdminAuth = Loadable({
  loader: () => import(/* webpackChunkName: 'admin-auth-page' */ './components/admin/auth'),
  loading: () => <Loader size={40} />,
  modules: ['admin-auth-page']
});

const Routes = () => (
  <Layout>
    <Switch>
      <Route exact path={pages.main} component={Main} />
      <Route exact path={pages.contacts} component={Contacts} />
      <Route exact path={pages.policy} component={Policy} />
      <Route exact path={pages.offer} component={Offer} />
      <Route exact path={pages.paymentDone} component={PaymentDone} />
    </Switch>

    <Switch>
      <Route exact path={pages.projects} component={Projects} />
      <Route exact path={pages.project} component={Project} />
    </Switch>

    <Switch>
      <Route exact path={pages.employees} component={Employees} />
      <Route exact path={pages.employee} component={Employee} />
    </Switch>

    <Switch>
      <Route exact path={pages.news} component={News} />
      <Route exact path={pages.newsDetail} component={NewsDetail} />
      <Route exact path={pages.projectNews} component={ProjectNews} />
    </Switch>

    <Switch>
      <Route exact path={pages.partners} component={Partners} />
      <Route exact path={pages.donors} component={Partners} />
      <Route exact path={pages.infrastructurePartners} component={Partners} />
      <Route exact path={pages.informationPartners} component={Partners} />
    </Switch>

    <Switch>
      <Route exact path={pages.missionAndTargets} component={Mission} />
      <Route exact path={pages.boardOfTrustees} component={BoardOfTrustees} />
      <Route exact path={pages.foundingDocuments} component={FoundingDocuments} />
      <Route exact path={pages.reports} component={Reports} />
      <Route exact path={pages.requisites} component={Requisites} />
      <Route exact path={pages.thanksgivingLetters} component={ThanksgivingLetters} />
      <Route exact path={pages.massMedia} component={MassMedia} />
      <Route exact path={pages.corporateDonations} component={CorporateDonations} />
      <Route exact path={pages.volunteer} component={Volunteer} />
    </Switch>

    <Switch>
      <Route exact path={pages.admin.main} component={Admin} />
      <Route exact path={pages.admin.auth} component={AdminAuth} />
      <Route exact path={pages.admin.projects} component={Admin} />
      <Route exact path={pages.admin.projectDetail} component={Admin} />
      <Route exact path={pages.admin.news} component={Admin} />
      <Route exact path={pages.admin.newsDetail} component={Admin} />
      <Route exact path={pages.admin.employees} component={Admin} />
      <Route exact path={pages.admin.employeesDetail} component={Admin} />
      <Route exact path={pages.admin.massMedia} component={Admin} />
      <Route exact path={pages.admin.partners} component={Admin} />
      <Route exact path={pages.admin.partnersDetail} component={Admin} />
      <Route exact path={pages.admin.documents} component={Admin} />
      <Route exact path={pages.admin.documentsDetail} component={Admin} />
    </Switch>
  </Layout>
);

export default Routes;
