import { pages } from '../../../routes';

const getLinkClass = (location, href, subClass) => {
  if (subClass) {
    return location.pathname === href
      ? 'header-link active ' + subClass
      : 'header-link ' + subClass;
  } else {
    return location.pathname === href ? 'header-link active' : 'header-link';
  }
};

export default location => [
  {
    to: pages.main,
    text: 'Главная',
    className: getLinkClass(location, pages.main)
  },
  {
    to: pages.missionAndTargets,
    text: 'О фонде',
    className: 'header-link',
    type: 'main'
  },
  {
    to: pages.projects,
    text: 'Проекты',
    className: getLinkClass(location, pages.projects)
  },
  {
    to: pages.partners,
    text: 'Партнеры',
    className: 'header-link',
    type: 'partners'
  },
  {
    to: pages.contacts,
    text: 'Контакты',
    className: getLinkClass(location, pages.contacts)
  },
  {
    to: pages.volunteer,
    text: 'Помощь фонду',
    className: getLinkClass(location, pages.contacts, 'tablet'),
    type: 'volunteer'
  }
];
