import constants from './constants';

const initialState = {
  project: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.project:
      return {
        ...state,
        project: action.payload.project
      };
    case constants.field:
      state.project[action.payload.field] = action.payload.value;
      return {
        ...state
      };
    case constants.description:
      state.project.description[action.payload.field] = action.payload.value;
      return {
        ...state
      };
    case constants.subtargets:
      state.project.subtargets = action.payload.subtargets;
      return {
        ...state
      };
    case constants.subtargetsFields:
      state.project.subtargets[action.payload.index][action.payload.field] = action.payload.value;
      return {
        ...state
      };
    default:
      return state;
  }
}
