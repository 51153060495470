import React from 'react';
import './style.less';

const getProps = props => {
  let result = {
    ...props
  };

  delete result.isOpen;
  delete result.className;

  return result;
};

const Burger = props => (
  <button {...getProps(props)} className={props.isOpen ? 'burger close' : 'burger'}>
    <span />
  </button>
);

export default Burger;
