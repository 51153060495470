import React from 'react';
import './style.less';

const Label = props => (
  <label className={props.className ? props.className + ' label-container' : 'label-container'}>
    <div className="label">{props.label}</div>
    {props.children}
  </label>
);

export default Label;
