import React, { Component } from 'react';
import Label from '../label';
import './style.less';

const getProps = props => {
  let result = {
    ...props
  };
  delete result.className;
  delete result.message;
  delete result.autoSize;
  return result;
};

export default class Textarea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: null
    };
  }

  getHeight(autoSize) {
    if (autoSize) {
      this.setState({
        height: this.hiddenArea.clientHeight + 'px'
      });
    }
  }

  render() {
    const props = this.props;

    return (
      <Label
        label={props.label}
        className={props.className ? 'textarea-container ' + props.className : 'textarea-container'}
      >
        <textarea
          ref={area => (this.area = area)}
          style={{ height: this.state.height }}
          className={!props.autoSize ? 'textarea' : 'textarea auto-size'}
          {...getProps(props)}
          onKeyUp={() => this.getHeight(props.autoSize)}
          onKeyPress={() => this.getHeight(props.autoSize)}
        />

        <div
          ref={hiddenArea => (this.hiddenArea = hiddenArea)}
          className="textarea textarea-hidden"
        >
          {props.value}
        </div>

        {props.message && props.message.length > 0 && (
          <div className="textarea-message">{props.message}</div>
        )}
      </Label>
    );
  }
}
