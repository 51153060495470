import React, { Component } from 'react';
import { Toast } from '../guideline';
import Header from './header';
import Footer from './footer';
import SuccessModal from './success-modal';
import './style.less';

export default class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
      toastText: '',
      toastClassName: null
    };
  }

  componentDidMount() {
    window.showToast = this.showToast.bind(this);
  }

  showToast(text, className, exception) {
    if (exception) {
      console.error(exception);
    }

    this.setState({
      toastText: text,
      toastClassName: className,
      showToast: true
    });
  }

  hideToast() {
    this.setState({ showToast: false });
  }

  render() {
    const { props, state } = this;

    return (
      <div className="layout">
        <Header />
        <div className="layout-content">{props.children}</div>
        <Footer />
        <SuccessModal />

        {state.showToast && (
          <Toast className={state.toastClassName} close={this.hideToast.bind(this)}>
            {state.toastText}
          </Toast>
        )}
      </div>
    );
  }
}
