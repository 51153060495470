import React, { Component } from 'react';
import navigation from '../../../left-menu-layout/navigation';
import { Container } from '../../../../guideline';
import { pages } from '../../../../../routes';
import './style.less';

export default class Control extends Component {
  constructor(props) {
    super(props);

    this.state = {
      path: pages.main
    };
  }

  componentDidMount() {
    this.setState({ path: this.props.location.pathname });
  }

  render() {
    const { type } = this.props;

    return (
      <Container className="mobile-nav-menu">
        {navigation[type].map((link, key) => (
          <a
            href={link.to}
            key={key}
            className={
              this.state.path === link.to ? 'mobile-nav-menu-link active' : 'mobile-nav-menu-link'
            }
          >
            {link.text}
          </a>
        ))}
      </Container>
    );
  }
}
