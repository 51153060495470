import React from 'react';
import './style.less';

const DropdownRow = props => (
  <div className={`dropdown-row${props.selected ? ' selected' : ''}`} onMouseDown={props.onClick}>
    {props.children}
  </div>
);

export default DropdownRow;
