import constants from './constants';

const initialState = {
  project: null,
  partners: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.project:
      return {
        ...state,
        project: action.payload.project,
        partners: action.payload.partners
      };
    default:
      return state;
  }
}
