import constants from './constants';

const initialState = {
  employees: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.employees:
      return {
        ...state,
        employees: action.payload.employees
      };
    case constants.field:
      state.employees[action.payload.field] = action.payload.value;
      return {
        ...state
      };
    default:
      return state;
  }
}
