import React from 'react';
import Label from '../label';
import './style.less';

function getParams(props) {
  let params = { ...props };
  delete params.label;
  delete params.onChange;

  params.className = params.className ? 'radio-label ' + params.className : 'radio-label';
  params.className = props.disabled ? (params.className += ' disabled') : params.className;
  params.className = props.checked ? (params.className += ' checked') : params.className;

  return params;
}

const Radio = props => (
  <Label className="radio-label" {...getParams(props)} label={props.label}>
    <input
      type="radio"
      className="radio"
      disabled={props.disabled}
      checked={props.checked}
      onChange={props.onChange}
    />
  </Label>
);

export default Radio;
