import React, { Component } from 'react';
import Row from './row';
import './style.less';

export default class Dropdown extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isOpen: false,
      text: this.getText()
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        text: this.getText(nextProps.rows)
      });
    }
  }

  toggleMenu() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  closeMenu() {
    this.setState({ isOpen: false });
  }

  getText(rows = this.props.rows) {
    if (rows && rows.length > 0) {
      const selected = rows.filter(row => {
        return row.selected;
      })[0];
      return selected ? selected.value : '';
    } else {
      return '';
    }
  }

  render() {
    const { state, props } = this;
    const className = `dropdown${state.isOpen ? ' open' : ''}${
      props.className ? ' ' + props.className : ''
    }`;

    return (
      <div className={className} onBlur={() => this.closeMenu()}>
        <button className="dropdown-button" onClick={() => this.toggleMenu()}>
          <span className="dropdown-button-text">{state.text}</span>
          <span className={state.isOpen ? 'dropdown-caret open' : 'dropdown-caret'} />
        </button>

        {state.isOpen && (
          <div className="dropdown-menu">
            {props.rows &&
              props.rows.map((row, i) => (
                <Row key={i} selected={row.selected} onClick={row.onClick}>
                  {row.value}
                </Row>
              ))}
          </div>
        )}
      </div>
    );
  }
}
