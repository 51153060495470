import constants from './constants';

const initialState = {
  partners: null,
  filters: [
    { type: 0, value: 'Все', selected: true },
    { type: 1, value: 'Доноры', selected: false },
    { type: 2, value: 'Клиники и научные организации', selected: false },
    { type: 3, value: 'Информационные партнеры', selected: false },
    { type: 4, value: 'Инфраструктурные партнеры', selected: false }
  ]
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.getAll:
      return {
        ...state,
        partners: action.payload.partners
      };
    case constants.filters:
      return {
        ...state,
        filters: action.payload.filters
      };
    default:
      return state;
  }
}
