import React from 'react';
import Label from '../label';
import './style.less';

const getProps = props => {
  let result = {
    ...props
  };

  delete result.className;
  delete result.message;

  return result;
};

const Input = props => (
  <Label
    label={props.label}
    className={props.className ? 'input-container ' + props.className : 'input-container'}
  >
    <input className="input" {...getProps(props)} />
    {props.message && props.message.length > 0 && (
      <div className="input-message">{props.message}</div>
    )}
  </Label>
);

export default Input;
