import React from 'react';
import Loader from '../loader';
import './style.less';

const getProps = props => {
  let htmlProps = {
    ...props
  };

  htmlProps.className = `button${props.inProgress ? ' in-progress' : ''}${
    props.className ? ' ' + props.className : ''
  }`;

  delete htmlProps.inProgress;
  return htmlProps;
};

const Button = props => (
  <button {...getProps(props)}>
    {props.children}

    {props.inProgress && (
      <div className="loader-container">
        <Loader size={getProps(props).className.indexOf('small') === -1 ? 16 : 8} />
      </div>
    )}
  </button>
);

export default Button;
