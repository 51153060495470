import React from 'react';
import Label from '../label';
import './style.less';

function getParams(props) {
  let params = { ...props };
  delete params.label;
  delete params.onChange;

  params.className = params.className ? 'checkbox-label ' + params.className : 'checkbox-label';
  params.className = props.disabled ? (params.className += ' disabled') : params.className;
  params.className = props.checked ? (params.className += ' checked') : params.className;

  return params;
}

const checkbox = props => (
  <Label className="checkbox-label" {...getParams(props)} label={props.label}>
    <input
      type="checkbox"
      className="checkbox"
      disabled={props.disabled}
      checked={props.checked}
      onChange={props.onChange}
    />
  </Label>
);
export default checkbox;
