import constants from './constants';

const initialState = {
  partner: null,
  projects: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.partner:
      return {
        ...state,
        partner: action.payload.partner,
        projects: action.payload.projects
      };
    case constants.field:
      state.partner[action.payload.field] = action.payload.value;
      return {
        ...state
      };
    default:
      return state;
  }
}
