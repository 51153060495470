import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'query-string';
import { Button, Modal } from '../../guideline';
import { pages } from '../../../routes';
import './style.less';

export default class SuccessModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      text: null,
      project: null
    };
  }

  componentDidMount() {
    window.showSuccessModal = (type, project) => this.showModal(type, project);
    window.closeSuccessModal = () => this.closeModal();

    let type = parse(window.location.search).type;
    if (type) this.showModal(type, parse(window.location.search).project);
  }

  showModal(type, project) {
    this.setState({
      type,
      project
    });
  }

  closeModal() {
    this.setState({ type: null, project: null });
  }

  getText() {
    const { type, project } = this.state;

    let texts = {
      payment: `Ваше пожертвование принято. Вы поддержали проект${
        project ? ' "' + project + '"' : '.'
      }`,
      issue: 'Заявка отправлена. Мы свяжемся с вами в ближайшее время.',
      message: 'Сообщение отправлено. Мы свяжемся с вами в ближайшее время.',
      paymentError: 'Ваш платеж не прошёл. Проверьте данные и попробуйте еще раз'
    };

    return texts[type] || 'Ваше обращение принято. Мы свяжемся с вами в ближайшее время.';
  }

  render() {
    return this.state.type ? (
      <div className="success-modal-container">
        <Modal className="success-modal" close={() => this.closeModal()}>
          <div className="success-modal-title">
            {this.state.type === 'paymentError' ? 'Ошибка' : 'Спасибо'}
          </div>

          <div className="success-modal-text">{this.getText()}</div>

          <Link to={pages.main}>
            <Button onClick={() => this.closeModal()}>На главную страницу</Button>
          </Link>
        </Modal>
      </div>
    ) : (
      <div />
    );
  }
}
