import constants from './constants';

const initialState = {
  document: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.document:
      return {
        ...state,
        document: action.payload.document
      };
    case constants.field:
      state.document[action.payload.field] = action.payload.value;
      return {
        ...state
      };
    default:
      return state;
  }
}
