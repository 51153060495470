import React from 'react';
import Slide from './slide/';
import './style.less';

const getClassForSlide = (currentSlide, index, count) => {
  if (currentSlide === index) {
    return 'current-slide';
  }
  if (currentSlide === index + 1) {
    return 'prev-slide';
  }
  if (currentSlide === 0 && index === count) {
    return 'prev-slide';
  }
  if (currentSlide === index - 1) {
    return 'next-slide';
  }
  if (currentSlide === count && index === 0) {
    return 'next-slide';
  }
  return 'hide-slide';
};

export default class Slider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      trend: 'next',
      disabled: false
    };
  }

  back() {
    let prevSlide = this.state.currentSlide - 1;
    if (prevSlide < 0) {
      prevSlide = this.props.children.length - 1;
    }
    this.setState({ currentSlide: prevSlide, trend: 'back', disabled: true });
    setTimeout(() => this.setState({ disabled: false }), 2000);
  }

  next() {
    let nextSlide = this.state.currentSlide + 1;
    if (nextSlide > this.props.children.length - 1) {
      nextSlide = 0;
    }
    this.setState({ currentSlide: nextSlide, trend: 'next', disabled: true });
    setTimeout(() => this.setState({ disabled: false }), 2000);
  }

  render() {
    const { props, state } = this;

    return (
      <div className="slider-container">
        <div className="slider" style={{ height: props.height, width: props.width }}>
          {props.children.length > 1 && (
            <div className="arrow-container">
              <button
                className="slider-arrow arrow-left"
                onClick={() => this.back()}
                disabled={state.disabled}
              />
              <button
                className="slider-arrow arrow-right"
                onClick={() => this.next()}
                disabled={state.disabled}
              />
            </div>
          )}

          {props.children.length > 1 ? (
            props.children.map((slide, i) => (
              <Slide
                key={i}
                className={getClassForSlide(state.currentSlide, i, props.children.length - 1)}
              >
                {slide}
              </Slide>
            ))
          ) : (
            <Slide className="show-slide">{props.children}</Slide>
          )}
        </div>
      </div>
    );
  }
}
