import { pages } from '../../../routes';

export default {
  main: [
    { to: pages.missionAndTargets, text: 'Миссия и цели' },
    { to: pages.boardOfTrustees, text: 'Попечительский совет' },
    { to: pages.employees, text: 'Сотрудники фонда' },
    { to: pages.foundingDocuments, text: 'Учредительные документы' },
    { to: pages.reports, text: 'Отчеты фонда' },
    { to: pages.requisites, text: 'Банковские реквизиты' },
    { to: pages.thanksgivingLetters, text: 'Благодарственные письма' },
    // { to: pages.massMedia, text: 'СМИ о фонде' },
    { to: pages.news, text: 'Новости и события' }
  ],
  partners: [
    { to: pages.donors, text: 'Доноры' },
    { to: pages.partners, text: 'Клиники и научные организации' },
    { to: pages.informationPartners, text: 'Информационные партнеры' },
    {
      to: pages.infrastructurePartners,
      text: 'Инфраструктурные партнеры'
    }
  ],
  volunteer: [
    {
      to: pages.corporateDonations,
      text: 'Корпоративные пожертвования'
    },
    { to: pages.volunteer, text: 'Стать волонтёром фонда' }
  ],
  admin: [
    { to: pages.admin.projects, text: 'Проекты' },
    { to: pages.admin.news, text: 'Новости' },
    { to: pages.admin.documents, text: 'Документы' },
    { to: pages.admin.employees, text: 'Сотрудники' },
    { to: pages.admin.massMedia, text: 'СМИ о фонде' },
    { to: pages.admin.partners, text: 'Партнеры' }
  ]
};
