import constants from './constants';

const initialState = {
  partners: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.partners:
      return {
        ...state,
        partners: action.payload.partners
      };
    default:
      return state;
  }
}
