import React from 'react';
import './style.less';

const Loader = props => (
  <div className="progress-loader" style={{ width: props.size || 40, height: props.size || 40 }}>
    <div className="progress-flipper">
      <div className="progress-front" />
      <div className="progress-back" />
    </div>
  </div>
);

export default Loader;
