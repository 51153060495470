import React, { Component } from 'react';
import './style.less';

export default class ProgressBar extends Component {
  render() {
    const side = this.props.side || 'width';

    return (
      <div className="progress-bar">
        <div className="current-progress" style={{ [side]: this.props.percent + '%' }} />
      </div>
    );
  }
}
