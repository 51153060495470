import constants from './constants';

const initialState = {
  files: [],
  rejectFiles: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.files:
      return {
        ...state,
        files: action.payload.files,
        rejectFiles: action.payload.rejectFiles
      };
    default:
      return state;
  }
}
