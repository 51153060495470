import { combineReducers } from 'redux';

import main from '../components/main/reducer';
import news from '../components/news/reducer';
import newsDetail from '../components/news-detail/reducer';
import project from '../components/project/reducer';
import form from '../components/main/form/reducer';
import contactForm from '../components/contacts/form/reducer';
import partners from '../components/partners/reducer';

import adminProjects from '../components/admin/projects/reducer';
import adminProject from '../components/admin/project-detail/reducer';
import adminNews from '../components/admin/news/reducer';
import adminNewsDetail from '../components/admin/news-detail/reducer';
import adminPartners from '../components/admin/partners/reducer';
import adminPartnersDetail from '../components/admin/partners-detail/reducer';
import adminMassMedia from '../components/admin/mass-media/reducer';
import adminEmployees from '../components/admin/employees/reducer';
import adminEmployeesDetail from '../components/admin/employees-detail/reducer';
import adminDocuments from '../components/admin/documents/reducer';
import adminDocumentsDetail from '../components/admin/documents-detail/reducer';
import dropZone from '../components/admin/drop-zone/reducer';

export default combineReducers({
  main,
  news,
  newsDetail,
  project,
  form,
  contactForm,
  partners,
  dropZone,
  adminProjects,
  adminProject,
  adminNews,
  adminNewsDetail,
  adminPartners,
  adminPartnersDetail,
  adminMassMedia,
  adminEmployees,
  adminEmployeesDetail,
  adminDocuments,
  adminDocumentsDetail
});
