import React, { Component } from 'react';
import './style.less';

export default class Toast extends Component {
  componentDidMount() {
    const { props } = this;
    let timeout = props.timeout || 3700;

    setTimeout(() => {
      this.toast.classList.add('hide-animation');
    }, timeout - 700);

    setTimeout(() => {
      if (props.close) {
        props.close();
      }
    }, timeout);
  }

  render() {
    const { props } = this;

    return (
      <div
        ref={toast => (this.toast = toast)}
        className={props.className ? 'toast ' + props.className : 'toast'}
      >
        {props.children}
      </div>
    );
  }
}
