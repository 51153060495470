export const port = 2000;
export const service = 'http://localhost:8088/data';
export const domain = 'http://ebmfoundation.ru';

export const pages = {
  main: '/',
  partners: '/partners',
  contacts: '/contacts',
  missionAndTargets: '/mission-and-targets',
  boardOfTrustees: '/board-of-trustees',
  foundingDocuments: '/founding-documents',
  reports: '/reports',
  requisites: '/requisites',
  thanksgivingLetters: '/thanksgiving-letters',
  massMedia: '/mass-media',
  corporateDonations: '/corporate-donations',
  volunteer: '/volunteer',
  donors: '/donors',
  informationPartners: '/information-partners',
  infrastructurePartners: '/infrastructure-partners',
  policy: '/policy',
  offer: '/offer',

  employees: '/employees',
  employee: '/employee/:id',

  projects: '/projects',
  project: '/project/:id',

  news: '/news',
  newsDetail: '/news/:id',
  projectNews: '/project-news/:id',

  paymentDone: '/payment-done/',

  admin: {
    main: '/admin-panel',
    auth: '/admin-panel/auth',
    projects: '/admin-panel/projects',
    projectDetail: '/admin-panel/projects/:id',
    news: '/admin-panel/news',
    newsDetail: '/admin-panel/news-detail/:id',
    employees: '/admin-panel/employees',
    employeesDetail: '/admin-panel/employees-detail/:id',
    massMedia: '/admin-panel/mass-media',
    partners: '/admin-panel/partners',
    partnersDetail: '/admin-panel/partners-detail/:id',
    documents: '/admin-panel/documents',
    documentsDetail: '/admin-panel/documents-detail/:id'
  }
};

export const posts = {
  main: {
    sendMessage: '/send-message'
  },

  news: {
    getAllNews: '/news/all',
    getProjectNews: '/news/project',
    detail: '/news/detail',
    delete: '/news/delete',
    create: '/news/create',
    update: '/news/update'
  },

  project: {
    all: '/project/all',
    detail: '/project/detail',
    delete: '/project/delete',
    create: '/project/create',
    update: '/project/update'
  },

  partners: {
    all: '/partners/all',
    detail: '/partners/detail',
    delete: '/partners/delete',
    create: '/partners/create',
    update: '/partners/update'
  },

  employees: {
    all: '/employees/all',
    detail: '/employees/detail',
    delete: '/employees/delete',
    create: '/employees/create',
    update: '/employees/update'
  },

  documents: {
    all: '/documents/all',
    detail: '/documents/detail',
    delete: '/documents/delete',
    create: '/documents/create',
    update: '/documents/update'
  },

  massMedia: {
    all: '/mass-media/all',
    delete: '/mass-media/delete',
    create: '/mass-media/create',
    update: '/mass-media/update'
  },

  payment: {
    sendForm: '/payment/send-form',
    cancel: '/payment/cancel'
  },

  admin: {
    saveImage: '/admin/save-img',
    deleteImage: '/admin/delete-img',
    auth: '/api/auth'
  }
};

export const links = {
  phone: '',
  phoneText: '8 (800) 775-19-96',
  mail: 'mailto:admin@gepatitu.net',
  mailText: 'admin@gepatitu.net'
};
