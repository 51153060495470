import React, { Component } from 'react';
import './style.less';

export default class Slide extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.className.indexOf('hide') === -1 || this.props.className.indexOf('hide') === -1
    );
  }

  render() {
    return <div className={'slide ' + this.props.className}>{this.props.children}</div>;
  }
}
