import React from 'react';
import { Container } from '../../guideline';
import { links } from '../../../routes';
import './style.less';

const Footer = () => (
  <footer className="footer">
    <Container className="full-screen footer-container">
      <div className="copyright">© Фонд доказательной медицины, 2018</div>
      <div className="contacts">
        <a className="phone" href={links.phone}>
          {links.phoneText}
        </a>

        <a className="email" href={links.mail}>
          {links.mailText}
        </a>
      </div>
    </Container>
  </footer>
);

export default Footer;
