import constants from './constants';

const initialState = {
  documents: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.getAll:
      return {
        ...state,
        documents: action.payload.documents
      };
    default:
      return state;
  }
}
