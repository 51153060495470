import constants from './constants';

const initialState = {
  tab: 'internet',
  paymentPeriod: 'once',
  paymentType: 'card',
  sumList: {
    100: true,
    500: false,
    1000: false,
    2000: false,
    5000: false,
    10000: false
  },
  anotherSum: 0,
  projects: null,
  isAnonymous: false,
  email: '',
  firstName: '',
  lastName: '',
  validate: null,
  accept: true,
  openCancelModal: false,
  cancelEmail: ''
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.cancelModal:
      return {
        ...state,
        openCancelModal: action.payload.openCancelModal
      };
    case constants.cancelEmail:
      return {
        ...state,
        cancelEmail: action.payload.cancelEmail
      };
    case constants.tab:
      return {
        ...state,
        tab: action.payload.tab
      };
    case constants.paymentPeriod:
      return {
        ...state,
        paymentPeriod: action.payload.paymentPeriod
      };
    case constants.paymentType:
      return {
        ...state,
        paymentType: action.payload.paymentType
      };
    case constants.sumList:
      return {
        ...state,
        sumList: action.payload.sumList,
        anotherSum: 0
      };
    case constants.anotherSum:
      return {
        ...state,
        anotherSum: action.payload.anotherSum
      };
    case constants.projects:
      return {
        ...state,
        projects: action.payload.projects
      };
    case constants.isAnonymous:
      return {
        ...state,
        isAnonymous: action.payload.isAnonymous,
        validate: null
      };
    case constants.email:
      return {
        ...state,
        email: action.payload.email,
        validate: null
      };
    case constants.firstName:
      return {
        ...state,
        firstName: action.payload.firstName,
        validate: null
      };
    case constants.lastName:
      return {
        ...state,
        lastName: action.payload.lastName
      };
    case constants.accept:
      return {
        ...state,
        accept: action.payload.accept
      };
    case constants.validate:
      return {
        ...state,
        validate: action.payload.validate
      };
    default:
      return state;
  }
}
